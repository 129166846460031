// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-list-template-js": () => import("./../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-champions-js": () => import("./../src/pages/champions.js" /* webpackChunkName: "component---src-pages-champions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-puppies-js": () => import("./../src/pages/puppies.js" /* webpackChunkName: "component---src-pages-puppies-js" */)
}

